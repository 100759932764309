import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import Button from 'src/components/Button'
import Layout from 'src/components/Layout'
import { withAuthServerSideProps } from 'src/hoc/withAuth'

import styles from './Index.module.scss'

const Index = () => {
  const router = useRouter()

  return (
    <Layout title="Home | Citifyd Enforcement App">
      <div className={styles.index}>
        <div className={styles.logo}>
          <Image height={176} src="/static/images/logo.svg" width={126} />
        </div>

        <Button
          className={styles.button}
          onClick={() =>
            void router.push({
              pathname: '/login',
              query: {
                ...router.query,
                type: 'phoneNumber',
              },
            })
          }
        >
          Continue with phone number
        </Button>
        <Button
          className={styles.button}
          onClick={() =>
            void router.push({
              pathname: '/login',
              query: {
                ...router.query,
                type: 'email',
              },
            })
          }
        >
          Continue with email
        </Button>
        <Link href="/forgot-password">
          <a className={styles.link}>Forgot password?</a>
        </Link>
      </div>
    </Layout>
  )
}

export const getServerSideProps = withAuthServerSideProps()

export default Index
