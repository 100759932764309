import classNames from 'classnames'
import React, { ReactNode } from 'react'

import styles from './Button.module.scss'

type ButtonProps = {
  children: ReactNode
  className?: string
  disabled?: boolean
  onClick?: () => void | Promise<any>
  outline?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const Button = ({ className, children, outline, ...rest }: ButtonProps) => {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.outline]: outline,
      })}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
