import Head from 'next/head'
import Image from 'next/image'
import React, { forwardRef, ReactNode } from 'react'

import { useHistory } from 'src/hooks/useHistory'
import { logout } from 'src/services/authentication'

import styles from './Layout.module.scss'

type Props = {
  actions?: ReactNode
  authHeader?: boolean
  backButton?: boolean
  backState?: string
  children?: ReactNode
  title?: string
}

const Layout = forwardRef<HTMLDivElement, Props>(
  (
    {
      backButton,
      children,
      backState,
      authHeader,
      actions,
      title = 'Citifyd Enforcement app',
    }: Props,
    ref,
  ) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { back } = useHistory()

    return (
      <div>
        <Head>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta
            content="initial-scale=1.0, width=device-width"
            name="viewport"
          />
        </Head>
        <div className={styles.layout}>
          <div className={styles.scrollable} ref={ref}>
            {backButton && (
              <button
                className={styles.backButton}
                onClick={() => back(backState)}
                type="button"
              >
                <Image
                  height={16}
                  src="/static/icons/arrow_left.svg"
                  width={16}
                />
              </button>
            )}
            {authHeader && (
              <div className={styles.authHeader}>
                <Image
                  height={36}
                  src="/static/images/logo_small.svg"
                  width={36}
                />
                <p className={styles.logout} onClick={() => logout()}>
                  Log out
                </p>
              </div>
            )}
            {children}
          </div>
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
      </div>
    )
  },
)

export default Layout
